.form-input {
  font: inherit;
  color: #000;
  width: 100%;
  border: none;
  border-bottom: solid 1px #000;
  border-radius: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
}

.skeleton {
  background-color: transparent !important;
  color: #d33939 !important;
  font-size: 14px !important;
  border: none;
}
.skeleton:hover {
  cursor: pointer;
}